.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.MuiPaper-root::-webkit-scrollbar{
  display: none !important;
}
.iconsMinhight{
  min-height: 82px !important;
}



.btnSelectimg {
  background-color: #fff;
  border: none;
  border-radius: 100%;
  height: 40px;
  position: absolute;
  right: 19%;
  top: 45%;
  width: 40px;
}

#tableId{
  border-spacing: 5px;
  border-collapse: separate;
  padding: 10px;
 }
 tr,td{
   border: 1px solid #eee;
   color: #3d3d3d;
 }
 th,td,tr{
   padding: 10px;
 }
 
 th{
   background-color: #0000a0;
   color: white;
   position: sticky;
   top: -15px;
   font-size: 14px;
 }
 #search-form{
  border: 1px solid #999;
  padding: 10px;
  border-radius: 6px;
  background-color: transparent !important;
  width: 300px;
}
.custom-select{
  height: 100%;
  border: 1ps solid #999 !important;
  background-color: transparent;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.imgClassNew{
mix-blend-mode: hard-light;
filter: brightness(70%);
}
.imgClassNew1{
  mix-blend-mode: darken;
  }

  .report-style-class{
    height: 100% !important;
    width: 100% !important;
  }

  #userTableinfo{
    width: 100%;
    border-collapse: separate;
    border-spacing: 5px;
    height: 100%;
    
  }
  #userTableinfo td{
    border: 1px solid #eee;
    padding: 1rem;
    position: relative;
    font-weight: normal;
    background-color: white;
  }
  #userTableinfo th{
    border: 1px solid #eee;
    padding: 1rem;
    position: inherit;
    background-color: #282c34;
  }

  .maleGen{
    background-color: rgb(199, 248, 199);
    color: #082b08;
    padding: 10px;
    border-radius: 6px;
  }
  .femaleGen{
    background-color: rgb(250, 200, 143);
    color: rgb(48, 28, 5);
    padding: 10px;
    border-radius: 6px;
  }
  .otherGen{
    background-color: rgb(242, 248, 152);
    color: rgb(76, 83, 7);
    padding: 10px;
    border-radius: 6px;
  }
  .memberSub{
    background-color: rgb(247, 135, 135);;
    color: rgb(36, 4, 4);
    padding: 10px;
    border-radius: 6px;
  }
  .adminSub{
    background-color: rgb(242, 248, 152);
    color: rgb(76, 83, 7);
    padding: 10px;
    border-radius: 6px;
  }
  .emailDiv{
    background-color: #d8d3d3;
    padding: 5px;
    border-radius: 5px;
    font-weight: normal;
    color: #282c34;
  }
  .userActveList{
    background-color: #eee;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: hidden;
    overflow-y: auto;
    gap: 3px;
  }
  .useritem{
    padding: 10px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    background-color: white;
  }
  .userMessageBox{
    width: 100%;
    height: 100%;
    background-color: #eee;
    height: 100%; /* Ensures the userMessageBox takes up the entire height of its parent container */
    display: flex; /* Use flexbox to manage the layout */
    flex-direction: column; /* Stack child elements vertically */
    gap: 10px;
    overflow: hidden;

  }
  .userActveList::-webkit-scrollbar{
    display: none !important;
  }
  .img_circle{
    width: 40px;
    height: 40px;
    background-color: #999;
    border-radius: 100%;
  }
  .messageBoxField{
    background-color: white;
    height:100%; /* Take up 90% of the userMessageBox height */
    max-height: calc(90% - 70px); /* Take up 90% of the userMessageBox height */

    box-shadow: 0 0 4px rgb(76, 83, 7,0.9);
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    overflow: hidden;

  }
  .messageTextField{
    background-color: white;
    height: 60px; /* Take up 10% of the userMessageBox height */
    box-shadow: 0 0 4px rgb(76, 83, 7,0.9);
    padding: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    flex-direction: row; /* Stack child elements vertically */
    gap: 10px;

  }
  .messageinputBox{
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid #a1a0a0;
    border-radius: 6px;

  }
  .messageTextField_1{
    width: 95%;
  }
  .messageTextField_2{
    width: 5%;
  }
  .myMessagecls{
    display: flex;
    justify-content: end;
    max-width: 80%;

  }
  .myMessageList1{
    align-self: flex-end;
    border: 1px solid;
    border-color: #eee !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 5px;
    background-color: initial;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #282c34;
    padding: 15px 20px;
    }
  .myMessageList2{
    align-self: flex-start;
    border: 1px solid;
    border-color: #eee !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 25px;
    width: auto;
    background-color: initial;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #282c34;
    padding: 15px 20px;
    
    
  }

  .userMessageCls{
    display: flex;
    justify-content: flex-start;
    max-width: 80%;


  }
  .listOfmessages{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding: 10px;

  }
  .message {
    border-radius: 5px;
    margin: 5px;
    max-width: 80%;
    padding: 10px;
}
.user{
  background-color: initial;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #282c34;
    padding: 15px 20px;
    align-self: flex-end;
    border: 1px solid;
    border-color: #eee !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 5px;

}
.Umessages{
  align-self: flex-start;
  border: 1px solid;
  border-color: #eee !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 25px;
  width: auto;
  background-color: initial;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #282c34;
    padding: 15px 20px;
}

#ListOfBonds{
  .MuiBox-root{
    padding: 0px !important;
    padding-top: 24px !important;
  }
}
.HighestRedeemer{
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 35px;
  color: green;
  padding-top: 10px;

}
.HighestRedeemer1{
  font-size: 14px !important;
  font-weight: 700 !important;
  color: red;
  min-height: 35px;
  padding-top: 10px;


}
.cardcls{
  min-height: 35px !important;
}
#pariesId{
  height: 100%;
  .MuiPaper-root{
    height: 100%;
  }
}
.donorCard2{

  min-height: 55px;

}