.login_body_grid {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 65% 35%;
    overflow: hidden;
}

.left_grid_text {
    color: white;
    width: 90%;
    margin: auto;
    display: block;
}

.right_grid_align {
    height: 100%;
    display: grid;
    align-content: center;
}

.btn_login {
    height: 40px;
    color: white;
    border: none;
    width: 70%;
    border-radius: 6px;
    cursor: pointer;
}

.left_margin {
    margin-top: 20px;
    padding-bottom: 20px !important;
}

.logout {
    cursor: pointer;
}

.login_body_content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.loginPage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;

}

.textAlign {
    text-align: left;
}

.MarsLogo {
    justify-content: center;
    align-content: center;
    width: 100%;
    display: grid;
}

.login_info {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}


.login_bg_image{
    background-repeat: no-repeat !important;
    background-size: cover;
}

.loginPage_new_form{
    display: grid;
    gap: 5px;
  }
  
  .loginPage_new_form_input{
    width: 100%;
    height: 50px;
    background-color: #e8f0ef;
    border: 1px solid #eee;
    border-radius: 4px;
    outline: none;
    transition: .4s;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
  .new-login-btn-alignment{
    display: grid;
    width: 92%;
    margin: auto;
    margin-bottom: 1rem;
    gap: 10px;
  }
  
  .text_overFlow-main {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    height: auto;
    overflow: hidden;
  }
  .dialog-description-new .MuiDialog-paper{
    min-width: 390px !important ;
    max-width: 390px !important ;
  
  }

  .dialog-description-new1 .MuiDialog-paper{
    max-width: 790px !important ;
  
  }
  
  #logoutPopup .MuiDialog-paper{
    min-width: 390px !important ;
    max-width: 390px !important ;
  
  }
  
  .listToggle{
    height: 100%;
  }
  
  .validation{
    display: grid;
    grid-gap: 10px;
    margin-top: 2rem;
  }
  .validation .validator{
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
    gap: 10px;
  }
  
  .errorIcon{
    color: red;
  }
  
  .successIcon{
    color: green;
  }
  
  .listToggle{
    align-items: baseline !important;
    align-content: baseline;
  }
  .form-label{
    color: white;
    text-align: left;
    justify-content: flex-start;
    display: grid;
  }