.sideNav{
    background-color: #0000a0;
  }
  .nav_bar{
    background-color: #0000a0 ;
  }
  .submit_btn{
    background-image: linear-gradient(243.04deg,#0000a0 -1.66%,#0000a0 50.23%,#0000a0 99.97%) !important;
    color: white !important;
  }
  .submit_btn_new-login{
    background-image: linear-gradient(243.04deg,#0000a0 -1.66%,#0000a0 50.23%,#0000a0 99.97%) !important;
    color: white !important;
    width: 140px;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 6px;
  
  }
  .btn_login{
    background-image: linear-gradient(243.04deg,#6271fa -1.66%,#6271fa 50.23%,#6271fa 99.97%) !important;
  }
  .bgColor{
    --tw-bg-opacity: 1;
    background-color: #23232c !important;
  
  }
  
  .headerTextFontcolorNew{
    color: #333 !important;
  }
  .loginText1{
    color: white !important;
  }
  
  .navBarStyle{
    background-color: #23232c;
  
  }
  .navBarStyleColor{
    color: white !important;
  }
  .MuiListItemIcon-root{
    color: white !important;
  
  }
  
  
  .navbgColor{
      --tw-bg-opacity: 1;
      background-color: rgba(32,33,35,var(--tw-bg-opacity));
  }
  
  #homePage{
    background: #ffffff;
  }
  
  .Gsvclass > div{
    color: white !important;
  }
  
  #statistic .MuiBox-root{
    border-color: white !important;
  }
  
  #statistic .MuiTab-textColorPrimary{
    color: white !important;
  
  }
  
   #sidenavBarnew .Mui-selected {
      background-color: #606066 !important;
      color: white !important;
  }
  
  .faqnewHeader,.faqnewSubHeader{
    color: white !important;
  }
  
  #statistic .MuiTabs-indicator{
    background-color: #23232c !important;
  }
  
  #chat_pdf_input{
    background-color: #333 !important;
    height: 55px !important;
    color: #3d3d3d !important;
  }
  .inputTextboxHome{
    background-color: #fff !important;
    margin: auto !important;
    width: 100% !important;
    border: none !important;
    padding: 0px !important;
  
  }
  #send_pdf_chat{
    display: grid;
    align-items: center;
    color: #80868b !important;
    border: 1px solid;
    border-color:#999 !important;
  
  }
   #chat_pdf_input::placeholder {
        color: white !important;
    }
    .inputTextboxHome{
      margin-right: 45px !important;
    }
    .goBackColor,.textColor{
      color: white !important;
    }
  
    #MultiCheckbox_div{
      background-color: transparent !important;
    }
  
    #homePage .card {
      background-color: #fff !important;
    }
    #history .card{
      background-color: transparent !important;
    }
    .bot,.user{
      color: #3d3d3d !important;
    }
  
    .bg-dark-card{
      color: #3d3d3d !important;
    }
    .faqSections{
      background-color: transparent !important;
      --tw-text-opacity: 1;
      --tw-border-opacity: 1;
      color: rgba(197,197,210,var(--tw-text-opacity)) !important;
      border-color: rgba(86,88,105,var(--tw-border-opacity)) !important;
      border: none;
    }
    #textbodyarea{
      width: 99% !important;
    }
    #textboxChats{
      border-top: none !important;
      border-bottom: none !important;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2) !important;
    }
  
    .tabSelected{
      background-color: #eee !important;
      color: #0000a0 !important;
      border-bottom: 3px solid #0000a0 !important;
    }
    .tabChatroom{
      background-color: #fff !important;
      color: black !important;
    }
  
    #history .card-body{
      padding: 0px !important;
    }
  
    #history #textbodyarea{
      background-color: #fff !important;
    }
    #history .card{
      border: none;
    }
  
    #tableId{
      background-color: #fff !important;
      padding: 2rem;
  
    }
  
     .active{
      background-color: #23232c !important;
      --bs-pagination-active-bg: #23232c !important;
      border: none;
    }
  
   a{
    text-decoration: none;
   }
   .text_header_color{
    font-size: 28px;
    font-weight: 700;
    padding-top: 1rem;
   }