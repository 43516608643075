body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.homePage_card{
  background: linear-gradient(135deg, rgba(232, 241, 236, 0.6), rgba(196, 201, 199, 0.6)) rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 6px;
}
.homePage_card_1{
  padding: 2rem;
  border-radius: 6px;
}
.box-flex-1{
  display: grid;
  gap: 10px;
}
.welcome-back-text-1{
  font-size: 30px;
  font-weight: 600;

}
.welcome-back-text-2{
  font-size: 22px;

}
.box-cls-2{
  display: grid;
  justify-content: flex-start;
}
.btn_go_now{
  background-color: #23232c;
  color: white;
  width: 120px;
  height: 45px;
  border: none;
  outline: none;
}



* {box-sizing: border-box}
body {font-family: Verdana, sans-serif; margin:0}
.mySlides {display: none;height: 300px;}
.mySlides .item {
  vertical-align: middle;  
  height: 300px;
  filter: brightness(70%);
  background-color: #23232c;
  border-radius:6px
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
  width: 100%;
  height: 40vh;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #283978;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  display: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */


/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active1 {
  background-color: #3a56f3 !important;
}

/* Fading animation */


/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}
.item_header{
  color: white;
  font-weight: 700;
  font-size: 24px;
}
.item{
  color: #f2f2f2;
  padding: 1rem;
  height: 100%;
  display: grid;
  align-items: center;
  align-content: center;
}